import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TenantType, UnitState, UnitType } from "../../../types";

const initialState: UnitState = {
  units: [],
  unitToBeEdited: null,
  currentUnit: null,
  openUnitModal: false,
  openUnitModalUpdate: false,
  openUnitModalDelete: false,
  openUnitTenantModal: false,
  activeTenant: null,
  openRentModal: false,
  openUtilityModal: false,
  currentItem: null,
};

const unitSlice = createSlice({
  name: "unit",
  initialState,
  reducers: {
    setUnits: (state, { payload }: PayloadAction<UnitType[]>) => {
      if (payload) {
        state.units = payload;
      }
    },
    setCurrentUnit: (state, { payload }: PayloadAction<UnitType>) => {
      if (payload) {
        state.currentUnit = payload;
      }
    },
    setCurrentItem: (state, { payload }: PayloadAction<any>) => {
      if (payload) {
        state.currentItem = payload;
      }
    },
    setUnit: (state, { payload }: PayloadAction<UnitType>) => {
      if (payload) {
        state.units = [...state.units, payload];
      }
    },
    UpdateUnit: (state, { payload }: PayloadAction<UnitType>) => {
      if (payload) {
        state.units = state.units.map((unit) =>
          unit.pkid === payload.pkid ? payload : unit
        );
      }
    },
    DeleteUnit: (state, { payload }: PayloadAction<UnitType>) => {
      if (payload) {
        state.units = state.units.filter((unit) => unit.pkid !== payload.pkid);
      }
    },
    setUnitToBeEdited: (state, { payload }: PayloadAction<string>) => {
      if (payload) {
        state.unitToBeEdited = payload;
      }
    },
    setUnitModal: (state, { payload }: PayloadAction<boolean>) => {
      state.openUnitModal = payload;
    },
    setUnitModalUpdate: (state, { payload }: PayloadAction<boolean>) => {
      state.openUnitModalUpdate = payload;
    },
    setUnitModalDelete: (state, { payload }: PayloadAction<boolean>) => {
      state.openUnitModalDelete = payload;
    },
    setUnitTenantModal: (state, { payload }: PayloadAction<boolean>) => {
      state.openUnitTenantModal = payload;
    },
    setUtilityModal: (state, { payload }: PayloadAction<boolean>) => {
      state.openUtilityModal = payload;
    },
    setRentModal: (state, { payload }: PayloadAction<boolean>) => {
      state.openRentModal = payload;
    },
    setActiveTenant: (state, { payload }: PayloadAction<TenantType>) => {
      if (payload) {
        state.activeTenant = payload;
      }
    },
    clearCurrentUnit: (state) => {
      state.currentUnit = null;
    },
    clearActiveTenant: (state) => {
      state.activeTenant = null;
    },
    clearUnits: (state) => {
      state.units = [];
    },
  },
});

export const {
  setUnit,
  setUnits,
  clearUnits,
  UpdateUnit,
  DeleteUnit,
  setUnitModal,
  setRentModal,
  setCurrentUnit,
  setCurrentItem,
  setUtilityModal,
  setActiveTenant,
  clearCurrentUnit,
  clearActiveTenant,
  setUnitToBeEdited,
  setUnitModalDelete,
  setUnitModalUpdate,
  setUnitTenantModal,
} = unitSlice.actions;

export default unitSlice.reducer;
