export enum ButtonType {
  button = "button",
  submit = "submit",
  reset = "reset",
}

export enum tabOptions {
  MEMBERS = "Members",
  INVITATIONS = "Invitations",
}

export enum SOCIAL_AUTH {
  GOOGLE = "google",
  FACEBOOK = "facebook",
}

export enum ResponseStatus {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  DENIED = "DENIED",
  INFO = "INFO",
}

export enum Screens {
  login = "login",
  register = "register",
  profile = "profile",
  reset_password = "reset_password",
  forgot_password = "forgot_password",
  email_verification = "email_verification",
  workspace = "workspace",
  units = "units",
  units_details = "units_details",
  buildings = "buildings",
  building_detail = "building_detail",
  services = "services",
  services_details = "services_details",
  properties = "properties",
  accept_invitation = "accept_invitation",
  tenants = "tenants",
}

export enum MobileTabs {
  Appointment = 1,
  Posts,
  Recovered,
  Security,
}

export enum UtilityType {
  Garbage = "Garbage",
  Water = "Water",
}

export enum RentType {
  Deposit = "Deposit",
  Rent = "Rent",
  Lease = "Lease",
}

export enum Months {
  January = "January",
  February = "February",
  March = "March",
  April = "April",
  May = "May",
  June = "June",
  July = "July",
  August = "August",
  September = "September",
  October = "October",
  November = "November",
  December = "December",
}

export enum Statuses {
  pending = "Pending",
  progress = "In Progress",
  done = "Done",
}
