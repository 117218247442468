import React, { useState, useRef, useEffect } from "react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import { SelectOptionType } from "../../types";

type Props = {
  label?: string;
  selected?: string;
  handleSelect: (value: string) => void;
  data: SelectOptionType[];
  placeholder?: string;
};

export default function Select({
  label,
  data,
  selected,
  handleSelect,
  placeholder = "Select an option",
}: Props) {
  const [menuDown, setMenuDown] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const isSelected = (value: string) => selected === value;

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setMenuDown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      {label && (
        <label className="block mb-2 text-[13px] text-gray-900">{label}</label>
      )}
      <div
        onClick={() => setMenuDown((prevState) => !prevState)}
        className="bg-white flex items-center justify-between border border-stroke px-3 py-3.5 rounded-md cursor-pointer"
      >
        <p className="text-[13px]">
          {selected
            ? data.find((option) => option.value === selected)?.label
            : placeholder}
        </p>
        <ChevronDownIcon
          className={`w-4 h-4 ${menuDown ? "rotate-180" : ""}`}
        />
      </div>
      {menuDown && (
        <div className="border border-stroke w-full rounded-md bg-white shadow-default absolute z-999 mt-1 max-h-60 overflow-y-auto">
          {data.map((option) => (
            <div
              key={option.value}
              onClick={() => {
                handleSelect(option.value);
                setMenuDown(false);
              }}
              className={`flex items-center cursor-pointer justify-between py-4 text-[13px] ${
                isSelected(option.value) ? "bg-[#a8b3cf1f]" : ""
              } border-b border-b-stroke text-meta-8 relative select-none px-4 hover:bg-gray-100`}
            >
              <p className="text-[13px]">{option.label}</p>
              {isSelected(option.value) && (
                <CheckIcon className="w-4 h-4 text-primary_color" />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
