import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PropertyType, PropertyTypes } from "../types";
import { RootState } from "../redux/store";
import {
  clearCurrentProperty,
  clearProperties,
  DeleteProperty,
  setCurrentProperty,
  setProperties,
  setProperty,
  setPropertyToBeEdited,
  UpdateProperty,
} from "../redux/features/properties/propertySlice";

const initialPropertyState: PropertyTypes = {
  value: "",
};

export default function useProperty() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] =
    React.useState<PropertyTypes>(initialPropertyState);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleClearForm = () => {
    setValues(initialPropertyState);
  };

  const state = useSelector((state: RootState) => state.property);

  const getCurrentProperty = useCallback(
    (property: any) => {
      dispatch(setCurrentProperty(property));
    },
    [dispatch]
  );

  const getProperties = useCallback(
    (properties: PropertyType[]) => {
      dispatch(setProperties(properties));
    },
    [dispatch]
  );

  const getProperty = useCallback(
    (property: PropertyType) => {
      dispatch(setProperty(property));
    },
    [dispatch]
  );

  const updateProperty = useCallback(
    (property: PropertyType) => {
      dispatch(UpdateProperty(property));
    },
    [dispatch]
  );

  const deleteProperty = useCallback(
    (property: PropertyType) => {
      dispatch(DeleteProperty(property));
    },
    [dispatch]
  );

  const getPropertyToBeEdited = useCallback(
    (property: string) => {
      dispatch(setPropertyToBeEdited(property));
    },
    [dispatch]
  );

  const clearProperty = useCallback(() => {
    dispatch(clearCurrentProperty());
  }, [dispatch]);

  const clearPropertyState = useCallback(() => {
    dispatch(clearProperties());
  }, [dispatch]);

  return {
    values,
    ...state,
    navigate,
    dispatch,
    setValues,
    getProperty,
    getProperties,
    clearProperty,
    deleteProperty,
    updateProperty,
    handleClearForm,
    clearPropertyState,
    handleInputChange,
    getCurrentProperty,
    getPropertyToBeEdited,
  };
}
