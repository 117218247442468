import React, { useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { CheckIcon } from "@heroicons/react/24/outline";
import useTenant from "../../hooks/useTenant";
import { TenantType } from "../../types";
import { capitalize } from "../../utils/stringUtils";
import useCustomQuery from "../../hooks/useCustomQuery";
import useWorkspace from "../../hooks/useWorkspace";
import useBuilding from "../../hooks/useBuilding";

export default function MenuSelectTenant() {
  const { currentBuilding } = useBuilding();
  const { currentWorkspace } = useWorkspace();
  const { getCurrentTenant, tenants, currentTenant } = useTenant();
  const [menuDown, setMenuDown] = useState<boolean>(false);

  const queryTenants = useCustomQuery(
    "tenants",
    `/workspaces/${currentWorkspace}/buildings/${currentBuilding?.slug}/tenants/`,
    !!currentBuilding || !!currentWorkspace
  );

  const toggle = () => {
    setMenuDown((prevState) => !prevState);
    if (!menuDown) {
      queryTenants.refetch();
    }
  };

  const handleSelectedTenant = (tenant: TenantType) => {
    if (tenant) {
      getCurrentTenant(tenant);
      setMenuDown(false);
    }
  };

  return (
    <div className="relative mt-1">
      <div
        onClick={toggle}
        className="bg-white flex items-center space-x-3 border border-stroke px-3 py-3 rounded-md cursor-pointer"
      >
        <div className="w-full flex items-center justify-between space-x-3">
          <p className="text-[13px] text-gray-900">
            {currentTenant && currentTenant ? (
              <span className="font-bold text-black/50">
                {capitalize(currentTenant?.last_name)}{" "}
                {capitalize(currentTenant?.first_name)}
              </span>
            ) : (
              "Select Tenant"
            )}
          </p>
        </div>
        <ChevronDownIcon
          className={`w-4 h-4 text-black/50 ${menuDown && "rotate-180"}`}
        />
      </div>
      <div
        className={`border border-stroke w-full rounded-md bg-white shadow-default ${
          menuDown ? "absolute z-999" : "hidden"
        }`}
      >
        {tenants?.map((tenant: TenantType) => (
          <div
            key={tenant?.pkid}
            onClick={() => handleSelectedTenant(tenant)}
            className={`flex items-center justify-between py-4 text-[13px] border-b border-b-stroke text-meta-8 relative cursor-pointer select-none px-4`}
          >
            <p className="text-[13px]">
              {capitalize(tenant?.last_name)} {capitalize(tenant?.first_name)}
            </p>
            {currentTenant?.pkid === tenant?.pkid && (
              <CheckIcon className="w-3 h-3 text-primary_color" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
