import React from "react";

type Props = {
  label?: string;
  type?: string;
  name?: string;
  placeholder?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  iconRight?: React.ReactNode;
  iconLeft?: React.ReactNode;
  styles?: string;
  disabled?: boolean;
  error?: string | null | boolean;
  onFocus?: VoidFunction;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  min?: number;
};

export default function Input({
  label,
  type,
  name,
  placeholder,
  value,
  onChange,
  iconRight,
  iconLeft,
  styles,
  disabled,
  error,
  onFocus,
  onKeyDown,
  min,
}: Props) {
  const [focus, setFocus] = React.useState(false);

  const handleFocus = () => {
    if (onFocus) {
      onFocus();
    } else {
      setFocus(true);
    }
  };

  const handleBlur = () => {
    setFocus(false);
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <label
          className={`block mb-2 text-[13px] sm:text-[13.5px] text-gray-900`}
        >
          {label}
        </label>
      </div>
      <div className="flex flex-col">
        <div
          className={`flex items-center ${styles} ${
            error
              ? "border-findit_red"
              : `${
                  focus
                    ? "outline-none border-primary_color ring-primary_color "
                    : "border-stroke"
                }`
          } `}
        >
          {iconLeft && iconLeft}
          <input
            type={type}
            name={name}
            onKeyDown={onKeyDown}
            value={value || ""}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={`h-full text-[13.5px] outline-none rounded text-gray-900 py-4.5 px-3 ${
              iconLeft ? "ml-4" : "mr-4"
            }  block w-full dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white`}
            placeholder={placeholder}
            disabled={disabled}
            onChange={onChange}
            min={min}
          />
          {iconRight && iconRight}
        </div>
        {error && <p className="text-findit_red text-xs">{error}</p>}
      </div>
    </div>
  );
}
