import React, { useEffect, useRef, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { CheckIcon } from "@heroicons/react/24/outline";
import { UnitType } from "../../types";
import useUnit from "../../hooks/useUnit";
import useTenant from "../../hooks/useTenant";

export default function MenuSelectUnit() {
  const { units } = useUnit();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [menuDown, setMenuDown] = useState<boolean>(false);
  const { getCurrentTenantUnit, currentTenantUnit } = useTenant();

  const toggle = () => setMenuDown((prevState) => !prevState);

  const handleSelectedUnit = (unit: UnitType) => {
    if (unit) {
      getCurrentTenantUnit(unit);
      toggle();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef?.current?.contains(event.target as Node)
      ) {
        setMenuDown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="relative mt-1">
      <div
        onClick={toggle}
        aria-haspopup="listbox"
        aria-expanded={menuDown}
        aria-controls="unit-dropdown"
        className="bg-white flex items-center space-x-3 border border-stroke px-3 py-3 rounded-md cursor-pointer"
      >
        <div className="w-full flex items-center justify-between">
          <p className="text-[13px] text-gray-900">
            {units.length > 0 &&
            currentTenantUnit !== null &&
            currentTenantUnit ? (
              <span className="font-bold text-black/50">
                {currentTenantUnit?.number}
              </span>
            ) : (
              "Select Unit"
            )}
          </p>
        </div>
        <ChevronDownIcon
          className={`w-4 h-4 text-black/50 ${menuDown && "rotate-180"}`}
        />
      </div>
      <div
        id="unit-dropdown"
        role="listbox"
        className={`border border-stroke w-full rounded-md bg-white shadow-default ${
          menuDown ? "absolute z-999" : "hidden"
        }`}
      >
        {units.length === 0 && (
          <p className="py-2 px-2 text-xs text-grey_black_3">
            No units available
          </p>
        )}
        {units.length > 0 &&
          units?.map((unit: UnitType) => (
            <div
              key={unit?.pkid}
              onClick={() => handleSelectedUnit(unit)}
              className={`w-full flex items-center justify-between py-4 text-[13px] border-b border-b-stroke text-meta-8 relative cursor-pointer select-none px-4`}
            >
              <p className="text-[13px]">{unit.number}</p>
              {currentTenantUnit !== null &&
                currentTenantUnit?.pkid === unit?.pkid && (
                  <CheckIcon className="w-3 h-3 text-primary_color" />
                )}
            </div>
          ))}
      </div>
    </div>
  );
}
