import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  RentTypes,
  TenantType,
  UnitType,
  UnitTypes,
  UtilityTypes,
} from "../types";
import { RootState } from "../redux/store";
import {
  clearActiveTenant,
  clearCurrentUnit,
  DeleteUnit,
  setActiveTenant,
  setCurrentItem,
  setCurrentUnit,
  setRentModal,
  setUnit,
  setUnitModal,
  setUnitModalDelete,
  setUnitModalUpdate,
  setUnits,
  setUnitTenantModal,
  setUnitToBeEdited,
  setUtilityModal,
  UpdateUnit,
} from "../redux/features/units/unitSlice";

const initialUnitState: UnitTypes = {
  number: "",
  rent_amount: "",
  rent_due_date: "",
};

const initialUtilityState: UtilityTypes = {
  ref_no: "",
  utility_type: "",
  amount_paid: "",
  year: "",
  month: "",
};

const initialRentState: RentTypes = {
  rent_type: "",
  amount: "",
  payment_date: "",
  rent_month: "",
  ref_no: "",
};

export default function useUnit() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] = useState<UnitTypes>(initialUnitState);
  const [utilityValues, setUtilityValues] =
    useState<UtilityTypes>(initialUtilityState);
  const [rentValues, setRentValues] = useState<RentTypes>(initialRentState);

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
      | { name: string; value: string }
  ) => {
    let name: string;
    let value: string;

    if ("target" in e) {
      name = e.target.name;
      value = e.target.value;
    } else {
      name = e.name;
      value = e.value;
    }

    setValues({
      ...values,
      [name]: value,
    });

    setUtilityValues({
      ...utilityValues,
      [name]: value,
    });

    setRentValues({
      ...rentValues,
      [name]: value,
    });
  };

  const handleClearForm = () => {
    setValues(initialUnitState);
    setUtilityValues(initialUtilityState);
    setRentValues(initialRentState);
  };
  const handleSelectChange = (name: string, value: string) => {
    handleInputChange({ name, value });
  };

  const state = useSelector((state: RootState) => state.unit);

  const getCurrentUnit = useCallback(
    (unit: UnitType) => {
      dispatch(setCurrentUnit(unit));
    },
    [dispatch]
  );

  const getCurrentItem = useCallback(
    (item: any) => {
      dispatch(setCurrentItem(item));
    },
    [dispatch]
  );

  const getActiveTenant = useCallback(
    (tenant: TenantType) => {
      dispatch(setActiveTenant(tenant));
    },
    [dispatch]
  );

  const getUnits = useCallback(
    (units: UnitType[]) => {
      dispatch(setUnits(units));
    },
    [dispatch]
  );

  const getUnit = useCallback(
    (unit: UnitType) => {
      dispatch(setUnit(unit));
    },
    [dispatch]
  );

  const updateUnit = useCallback(
    (unit: UnitType) => {
      dispatch(UpdateUnit(unit));
    },
    [dispatch]
  );

  const deleteUnit = useCallback(
    (unit: UnitType) => {
      dispatch(DeleteUnit(unit));
    },
    [dispatch]
  );

  const getUnitToBeEdited = useCallback(
    (unit: string) => {
      dispatch(setUnitToBeEdited(unit));
    },
    [dispatch]
  );

  const toggleUnitModal = useCallback(
    (toggle: boolean) => {
      dispatch(setUnitModal(toggle));
    },
    [dispatch]
  );
  const toggleUnitModalUpdate = useCallback(
    (toggle: boolean) => {
      dispatch(setUnitModalUpdate(toggle));
    },
    [dispatch]
  );
  const toggleUnitModalDelete = useCallback(
    (toggle: boolean) => {
      dispatch(setUnitModalDelete(toggle));
    },
    [dispatch]
  );

  const toggleUnitTenantModal = useCallback(
    (toggle: boolean) => {
      dispatch(setUnitTenantModal(toggle));
    },
    [dispatch]
  );

  const toggleRentModal = useCallback(
    (toggle: boolean) => {
      dispatch(setRentModal(toggle));
    },
    [dispatch]
  );

  const toggleUtilityModal = useCallback(
    (toggle: boolean) => {
      dispatch(setUtilityModal(toggle));
    },
    [dispatch]
  );

  const clearUnit = useCallback(() => {
    dispatch(clearCurrentUnit());
  }, [dispatch]);

  return {
    values,
    ...state,
    rentValues,
    utilityValues,
    navigate,
    dispatch,
    setValues,
    getUnit,
    getUnits,
    clearUnit,
    deleteUnit,
    updateUnit,
    getCurrentItem,
    setRentValues,
    getCurrentUnit,
    toggleRentModal,
    getActiveTenant,
    toggleUnitModal,
    handleClearForm,
    setUtilityValues,
    getUnitToBeEdited,
    handleInputChange,
    toggleUtilityModal,
    handleSelectChange,
    toggleUnitModalDelete,
    toggleUnitModalUpdate,
    toggleUnitTenantModal,
  };
}
