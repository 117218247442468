import React, { useEffect, useRef, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { CheckIcon } from "@heroicons/react/24/outline";
import useBuilding from "../../hooks/useBuilding";
import { BuildingType } from "../../types";
import { capitalize } from "../../utils/stringUtils";
import useCustomQuery from "../../hooks/useCustomQuery";
import useWorkspace from "../../hooks/useWorkspace";
import Spinner from "./Spinner";

export default function MenuSelectBuilding() {
  const { currentWorkspace } = useWorkspace();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [menuDown, setMenuDown] = useState<boolean>(false);
  const { buildings, currentBuilding, getCurrentBuilding } = useBuilding();

  const queryBuildings = useCustomQuery(
    "buildings",
    `/workspaces/${currentWorkspace}/buildings/`,
    !!currentWorkspace
  );

  useEffect(() => {
    if (menuDown && !!currentWorkspace) {
      queryBuildings.refetch();
    }
  }, [menuDown, currentWorkspace]);

  const toggleMenu = () => setMenuDown((prevState) => !prevState);

  const handleSelectedBuilding = (building: BuildingType) => {
    if (building) {
      getCurrentBuilding(building);
      toggleMenu();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef?.current?.contains(event.target as Node)
      ) {
        setMenuDown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="relative mt-1">
      <div
        onClick={toggleMenu}
        aria-haspopup="listbox"
        aria-expanded={menuDown}
        aria-controls="building-dropdown"
        className="bg-white flex items-center space-x-3 border border-stroke px-3 py-3 rounded-md cursor-pointer"
      >
        <div className="w-full flex items-center justify-between">
          <p className="text-[13px] text-gray-900">
            {buildings.length > 0 &&
            currentBuilding !== null &&
            currentBuilding ? (
              <span className="font-bold text-black/50">
                {capitalize(currentBuilding?.name)}
              </span>
            ) : (
              "Select Building"
            )}
          </p>
        </div>
        <ChevronDownIcon
          className={`w-4 h-4 text-black/50 ${menuDown && "rotate-180"}`}
        />
      </div>
      <div
        id="building-dropdown"
        role="listbox"
        className={`border border-stroke w-full rounded-md bg-white shadow-default ${
          menuDown ? "absolute z-999" : "hidden"
        }`}
      >
        {queryBuildings.isLoading && (
          <Spinner styles="h-3 w-3 border-2 text-primary_color" />
        )}

        {queryBuildings.isSuccess && buildings.length === 0 && (
          <p className="py-2 px-2 text-xs text-grey_black_3">
            No buildings available
          </p>
        )}
        {buildings?.map((building: BuildingType) => (
          <div
            key={building?.pkid}
            onClick={() => handleSelectedBuilding(building)}
            className={`w-full flex items-center justify-between py-3 text-[13px] border-b border-b-stroke text-meta-8 relative cursor-pointer select-none px-4`}
          >
            <p className="text-[13px]">{capitalize(building?.name)}</p>
            {currentBuilding?.name === building?.name && (
              <CheckIcon className="w-3 h-3 text-primary_color" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
