import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../redux/store";
import { getLocation } from "../utils/locationUtils";
import { useCallback } from "react";
import {
  setCurrentItem,
  setDeleteComponent,
  setDisplaySearch,
  setDropDown,
  setMenuDown,
  setOpen,
  setOpenCustomDeleteModal,
  setPhone,
  setTranslateY,
} from "../redux/features/app/appSlice";

export default function useApp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const value = getLocation(location);

  const state = useSelector((state: RootState) => state.app);

  const getCurrentItem = useCallback(
    (item: string) => {
      dispatch(setCurrentItem(item));
    },
    [dispatch]
  );

  const getDeleteComponent = useCallback(
    (item: string) => {
      dispatch(setDeleteComponent(item));
    },
    [dispatch]
  );

  const translate = useCallback(
    (translate: boolean) => {
      dispatch(setTranslateY(translate));
    },
    [dispatch]
  );

  const showSearch = useCallback(
    (show: boolean) => {
      dispatch(setDisplaySearch(show));
    },
    [dispatch]
  );

  const toggleDropDown = useCallback(
    (toggle: boolean) => {
      dispatch(setDropDown(toggle));
    },
    [dispatch]
  );

  const toggleMenu = useCallback(
    (toggle: boolean) => {
      dispatch(setMenuDown(toggle));
    },
    [dispatch]
  );

  const togglePhone = useCallback(
    (toggle: boolean) => {
      dispatch(setPhone(toggle));
    },
    [dispatch]
  );

  const toggleOpen = useCallback(
    (open: boolean) => {
      dispatch(setOpen(!open));
    },
    [dispatch]
  );

  const toggleOpenDeleteCustomModal = useCallback(
    (open: boolean) => {
      dispatch(setOpenCustomDeleteModal(open));
    },
    [dispatch]
  );

  return {
    value,
    ...state,
    navigate,
    location,
    translate,
    showSearch,
    toggleMenu,
    toggleOpen,
    togglePhone,
    toggleDropDown,
    getCurrentItem,
    getDeleteComponent,
    toggleOpenDeleteCustomModal,
  };
}
