import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "../../../types";

const initialState: AppState = {
  deleteComponent: "",
  openCustomDeleteModal: false,
  displaySearch: false,
  currentItem: null,
  translateY: false,
  dropDown: false,
  menuDown: false,
  checked: false,
  phone: false,
  open: false,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setCurrentItem: (state, { payload }: PayloadAction<string>) => {
      state.currentItem = payload;
    },
    setDeleteComponent: (state, { payload }: PayloadAction<string>) => {
      state.deleteComponent = payload;
    },
    setTranslateY: (state, { payload }: PayloadAction<boolean>) => {
      state.translateY = payload;
    },
    setDisplaySearch: (state, { payload }: PayloadAction<boolean>) => {
      state.displaySearch = payload;
    },
    setDropDown: (state, { payload }: PayloadAction<boolean>) => {
      state.dropDown = payload;
    },
    setMenuDown: (state, { payload }: PayloadAction<boolean>) => {
      state.menuDown = payload;
    },
    setOpenCustomDeleteModal: (state, { payload }: PayloadAction<boolean>) => {
      state.openCustomDeleteModal = payload;
    },
    setPhone: (state, { payload }: PayloadAction<boolean>) => {
      state.phone = payload;
    },
    setOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.open = payload;
    },
  },
});

export const {
  setTranslateY,
  setCurrentItem,
  setDeleteComponent,
  setDisplaySearch,
  setDropDown,
  setMenuDown,
  setPhone,
  setOpen,
  setOpenCustomDeleteModal,
} = appSlice.actions;

export default appSlice.reducer;
