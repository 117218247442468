import React from "react";
import {
  Menu,
  Table,
  Group,
  Text,
  ActionIcon,
  LoadingOverlay,
} from "@mantine/core";
import {
  EllipsisVerticalIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
  TrashIcon,
  DocumentMagnifyingGlassIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import SearchInput from "./SearchInput";
import Spinner from "./Spinner";
import { NavigateFunction } from "react-router-dom";
import { useLoadingData } from "../../hooks/useLoadingData";
import { unitColumns } from "../../utils/data";
import Button from "./Button";
import useUnit from "../../hooks/useUnit";

interface BuildingTableProps {
  data: any[];
  isLoading: boolean;
  navigate: NavigateFunction;
}

export default function UnitTable({
  data,
  isLoading,
  navigate,
}: BuildingTableProps) {
  const { toggleUnitModal, toggleUnitModalDelete, getCurrentUnit } = useUnit();
  const { sortedData, visible, search, handleSearchChange, handleReset } =
    useLoadingData(data, isLoading);

  const rows = sortedData?.map((item) => {
    return (
      <Table.Tr key={item?.pkid}>
        <Table.Td>
          <Group gap="sm">
            <Text fz="sm" fw={500}>
              {item?.number}
            </Text>
          </Group>
        </Table.Td>
        <Table.Td>
          <Group gap="sm">
            <Text fz="sm" fw={500}>
              {item?.rent_amount}
            </Text>
          </Group>
        </Table.Td>
        <Table.Td>
          <Group gap="sm">
            <Text fz="sm" fw={500}>
              {item?.rent_due_date}
            </Text>
          </Group>
        </Table.Td>

        <Table.Td>
          <Group gap={0} justify="flex-start">
            <Menu
              transitionProps={{ transition: "pop" }}
              withArrow
              position="bottom-end"
              withinPortal
            >
              <Menu.Target>
                <ActionIcon variant="subtle" color="gray">
                  <EllipsisVerticalIcon className="h-5 w-5" />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown className="flex w-62.5 bg-back2 bg-cover bg-no-repeat min-w-[16px] min-h-[16px] cursor-pointer flex-col transform transition-all duration-[198ms] backdrop-blur-xl rounded-lg border border-stroke bg-white/90 shadow-default">
                <Menu.Item
                  onClick={() => {
                    navigate(`/unit-details`);
                    getCurrentUnit(item);
                  }}
                  leftSection={
                    <DocumentMagnifyingGlassIcon className="h-4 w-4 text-blue-400" />
                  }
                  className="w-full hover:bg-background_color_second flex items-center space-x-3 px-3 py-1 text-sm rounded-md font-medium duration-300 ease-in-out lg:text-base"
                >
                  <p className="text-blue-400 text-[13px]">View Details</p>
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    toggleUnitModalDelete(true);
                    getCurrentUnit(item);
                  }}
                  leftSection={<TrashIcon className="h-4 w-4 text-red-400" />}
                  className="hover:bg-background_color_second flex items-center space-x-3 px-3 py-1 rounded-md text-sm font-medium duration-300 ease-in-out lg:text-base"
                >
                  <p className="text-red-400 text-[13px]">Delete unit</p>
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Table.Td>
      </Table.Tr>
    );
  });

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex items-center justify-between space-x-3">
        <div className="hidden md:flex">
          <h2 className="text-[20px] font-semibold text-primary_color dark:text-white">
            Units
          </h2>
        </div>
        <div className="w-full flex items-center justify-between md:justify-end space-x-4">
          <SearchInput
            styles="border border-stroke bg-white rounded-3xl focus:border-blue-500"
            placeholder="Search in units..."
            onChange={handleSearchChange}
            name="search"
            icon={<MagnifyingGlassIcon className="w-4 h-4 text-gray_color" />}
            value={search}
            iconRight={
              search.length > 0 && (
                <div
                  className="h-5 w-5 flex items-center rounded-full justify-center hover:bg-stroke cursor-pointer"
                  onClick={handleReset}
                >
                  <XMarkIcon className="w-3 h-3 text-gray_color" />
                </div>
              )
            }
          />
          <div>
            <Button
              text="Add a unit"
              leftIcon={<PlusIcon className="h-4 w-4 text-white" />}
              onClick={() => toggleUnitModal(true)}
              buttonStyle="px-4 py-2 text-white cursor-pointer rounded-md bg-primary_color flex items-center justify-center duration-700 ease-in-out hover:bg-primary_color/80"
            />
          </div>
        </div>
      </div>
      <Table.ScrollContainer
        minWidth={700}
        className="overflow-hidden relative border border-stroke rounded-md shadow-lg px-4 pt-4 pb-0"
      >
        <LoadingOverlay
          visible={visible}
          loaderProps={{
            children: <Spinner styles="h-4 w-4 border-2 text-primary_color" />,
          }}
        />
        <Table verticalSpacing="md">
          <Table.Thead>
            <Table.Tr>
              {unitColumns.map((item) => (
                <Table.Th key={item.id}>{item.label}</Table.Th>
              ))}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </Table.ScrollContainer>
      <div>
        <p className="text-[14.5px] sm:text-[15px] font-medium">
          {sortedData.length === 0
            ? "No units"
            : sortedData.length === 1
            ? "total: 1"
            : `total: ${sortedData.length}`}
        </p>
      </div>
    </div>
  );
}
