import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../redux/store";
import {
  clearCurrentService,
  clearServices,
  DeleteService,
  setCurrentService,
  setService,
  setServiceModal,
  setServiceModalDelete,
  setServices,
  setServiceToBeEdited,
  UpdateService,
} from "../redux/features/services/serviceSlice";
import { Service, ServiceType } from "../types";
import { Statuses } from "../types/enums";

const initialServiceState: Service = {
  notes: "",
  status: Statuses.pending,
  cost: "",
  due_date: "",
};

export default function useService() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] = React.useState<Service>(initialServiceState);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleClearForm = () => {
    setValues(initialServiceState);
  };

  const state = useSelector((state: RootState) => state.service);

  const getCurrentService = useCallback(
    (service: ServiceType) => {
      dispatch(setCurrentService(service));
    },
    [dispatch]
  );

  const getServices = useCallback(
    (services: ServiceType[]) => {
      dispatch(setServices(services));
    },
    [dispatch]
  );

  const getService = useCallback(
    (service: ServiceType) => {
      dispatch(setService(service));
    },
    [dispatch]
  );

  const updateService = useCallback(
    (service: ServiceType) => {
      dispatch(UpdateService(service));
    },
    [dispatch]
  );

  const deleteService = useCallback(
    (service: ServiceType) => {
      dispatch(DeleteService(service));
    },
    [dispatch]
  );

  const getServiceToBeEdited = useCallback(
    (service: string) => {
      dispatch(setServiceToBeEdited(service));
    },
    [dispatch]
  );

  const toggleServiceModal = useCallback(
    (toggle: boolean) => {
      dispatch(setServiceModal(toggle));
    },
    [dispatch]
  );

  const toggleServiceModalDelete = useCallback(
    (toggle: boolean) => {
      dispatch(setServiceModalDelete(toggle));
    },
    [dispatch]
  );

  const clearService = useCallback(() => {
    dispatch(clearCurrentService());
  }, [dispatch]);

  const clearServiceState = useCallback(() => {
    dispatch(clearServices());
  }, [dispatch]);

  return {
    values,
    ...state,
    navigate,
    dispatch,
    setValues,
    getService,
    getServices,
    clearService,
    updateService,
    deleteService,
    handleClearForm,
    handleInputChange,
    clearServiceState,
    getCurrentService,
    toggleServiceModal,
    getServiceToBeEdited,
    toggleServiceModalDelete,
  };
}
