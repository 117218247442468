import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BuildingState, BuildingType } from "../../../types";

const initialState: BuildingState = {
  buildings: [],
  buildingToBeEdited: null,
  currentBuilding: null,
  openBuildingModal: false,
  openBuildingModalDelete: false,
  openBuildingModalUpdate: false,
};

const buildingSlice = createSlice({
  name: "building",
  initialState,
  reducers: {
    setBuildings: (state, { payload }: PayloadAction<BuildingType[]>) => {
      if (payload) {
        state.buildings = payload;
      }
    },
    setCurrentBuilding: (state, { payload }: PayloadAction<BuildingType>) => {
      if (payload) {
        state.currentBuilding = payload;
      }
    },
    setBuilding: (state, { payload }: PayloadAction<BuildingType>) => {
      if (payload) {
        state.buildings = [...state.buildings, payload];
      }
    },
    UpdateBuilding: (state, { payload }: PayloadAction<BuildingType>) => {
      if (payload) {
        state.buildings = state.buildings.map((building) =>
          building.pkid === payload.pkid ? payload : building
        );
      }
    },
    DeleteBuild: (state, { payload }: PayloadAction<BuildingType>) => {
      if (payload) {
        state.buildings = state.buildings.filter(
          (building) => building.pkid !== payload.pkid
        );
      }
    },
    setBuildingToBeEdited: (state, { payload }: PayloadAction<string>) => {
      if (payload) {
        state.buildingToBeEdited = payload;
      }
    },
    setBuildingModal: (state, { payload }: PayloadAction<boolean>) => {
      state.openBuildingModal = payload;
    },
    setBuildingModalUpdate: (state, { payload }: PayloadAction<boolean>) => {
      state.openBuildingModalUpdate = payload;
    },
    setBuildingModalDelete: (state, { payload }: PayloadAction<boolean>) => {
      state.openBuildingModalDelete = payload;
    },
    clearCurrentBuilding: (state) => {
      state.currentBuilding = null;
    },
    clearBuildings: (state) => {
      state.buildings = [];
    },
  },
});

export const {
  setBuilding,
  DeleteBuild,
  setBuildings,
  clearBuildings,
  UpdateBuilding,
  setBuildingModal,
  setCurrentBuilding,
  clearCurrentBuilding,
  setBuildingToBeEdited,
  setBuildingModalDelete,
  setBuildingModalUpdate,
} = buildingSlice.actions;

export default buildingSlice.reducer;
