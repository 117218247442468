import React from "react";

type Props = {
  label?: string | React.ReactNode;
  icon?: React.ReactElement;
  styles?: string;
  inputStyles?: string;
  type?: File | any;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  multiple?: boolean;
  name: string;
  accept?: string;
};

export default function FileInput({
  label,
  type,
  name, 
  onChange,
  multiple,
  styles,
}: Props) {
  return (
    <label className={`${styles}`}>
      <h4 className="flex items-center lg:text-[13px] 2xl:text-[14px] justify-center border flex-1  w-full border-stroke py-4 px-2 rounded-md text-[#808080] dark:text-neutral-200">
        {label}
      </h4>
      <input
        className="hidden relative m-0 w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
        type={type}
        multiple={multiple}
        onChange={onChange}
        name={name}
      />
    </label>
  );
}
