import React, { useCallback } from "react";
import useGeolocation from "./useGeolocation";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  WorkspaceInvitationType,
  WorkspaceType,
  WorkspaceTypes,
} from "../types";
import { errorType } from "../types/types";
import { RootState } from "../redux/store";
import useImage from "./useImage";
import {
  clearCurrentWorkspace,
  DeleteWorkspace,
  setCurrentTab,
  setCurrentWorkspace,
  setInviteModal,
  setOpen,
  setWorkspace,
  setWorkspaceInvitations,
  setWorkspaceMembers,
  setWorkspaceModal,
  setWorkspaces,
  setWorkspaceToBeEdited,
  UpdateWorkspace,
} from "../redux/features/workspace/workSpaceSlice";

const initialWorkspaceState: WorkspaceTypes = {
  name: "",
  website_url: "",
  email: "",
  phone_number: "",
};

const initialWorkspaceInvitationState: WorkspaceInvitationType = {
  received_by: "",
};

export default function useWorkspace() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { unSelectImage } = useImage();
  const { country } = useGeolocation();
  const [values, setValues] = React.useState<WorkspaceTypes>(
    initialWorkspaceState
  );
  const [invitationValues, setInvitationValues] = React.useState<any>(
    initialWorkspaceInvitationState
  );
  const [errors, setErrors] = React.useState<errorType>({});

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    let phoneNumber = value;
    if (name === "phone_number" && country?.country_calling_code) {
      if (!value.startsWith(country.country_calling_code)) {
        phoneNumber = `${country.country_calling_code}${value}`;
      }
    }

    setValues({
      ...values,
      [name]: name === "phone_number" ? phoneNumber : value,
    });

    setInvitationValues({
      ...invitationValues,
      [name]: value,
    });
  };

  const validateWorkspace = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const phoneRegex = /^\+\d{3}\d{9}$/;
    let temp: errorType = {};

    temp.email = emailRegex.test(values.email || "")
      ? ""
      : "Invalid email format";
    temp.phone_number = phoneRegex.test(values.phone_number || "")
      ? ""
      : "Invalid phone number";
    setErrors({ ...temp });
    return Object.values(temp).every((x) => !x);
  };

  const validateWorkspaceInvitation = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    let temp: errorType = {};

    temp.email = emailRegex.test(invitationValues.received_by || "")
      ? ""
      : "Invalid email format";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => !x);
  };

  const handleClearForm = () => {
    setValues(initialWorkspaceState);
    setInvitationValues(initialWorkspaceInvitationState);
    unSelectImage();
    setErrors({});
  };

  const state = useSelector((state: RootState) => state.workspace);

  const getCurrentWorkspace = useCallback(
    (workspace: any) => {
      dispatch(setCurrentWorkspace(workspace));
    },
    [dispatch]
  );

  const getWorkspaces = useCallback(
    (workspaces: WorkspaceType[]) => {
      dispatch(setWorkspaces(workspaces));
    },
    [dispatch]
  );

  const getWorkspace = useCallback(
    (workspace: WorkspaceType) => {
      dispatch(setWorkspace(workspace));
    },
    [dispatch]
  );

  const updateWorkspace = useCallback(
    (workspace: WorkspaceType) => {
      dispatch(UpdateWorkspace(workspace));
    },
    [dispatch]
  );

  const deleteWorkspace = useCallback(
    (workspace: WorkspaceType) => {
      dispatch(DeleteWorkspace(workspace));
    },
    [dispatch]
  );

  const getWorkspaceToBeEdited = useCallback(
    (workspace: string) => {
      dispatch(setWorkspaceToBeEdited(workspace));
    },
    [dispatch]
  );

  const toggleOpen = useCallback(
    (toggle: boolean) => {
      dispatch(setOpen(toggle));
    },
    [dispatch]
  );

  const toggleOpenInviteModal = useCallback(
    (toggle: boolean) => {
      dispatch(setInviteModal(toggle));
    },
    [dispatch]
  );

  const toggleWorkspaceModal = useCallback(
    (toggle: boolean) => {
      dispatch(setWorkspaceModal(toggle));
    },
    [dispatch]
  );
  const trackCurrentTab = useCallback(
    (toggle: any) => {
      dispatch(setCurrentTab(toggle));
    },
    [dispatch]
  );
  const getWorkspaceMembers = useCallback(
    (members: any[]) => {
      dispatch(setWorkspaceMembers(members));
    },
    [dispatch]
  );

  const getWorkspaceInvitations = useCallback(
    (invitations: any) => {
      dispatch(setWorkspaceInvitations(invitations));
    },
    [dispatch]
  );
  const clearWorkspace = useCallback(() => {
    dispatch(clearCurrentWorkspace());
  }, [dispatch]);

  return {
    values,
    errors,
    ...state,
    dispatch,
    navigate,
    setValues,
    toggleOpen,
    getWorkspace,
    getWorkspaces,
    clearWorkspace,
    deleteWorkspace,
    updateWorkspace,
    trackCurrentTab,
    handleClearForm,
    invitationValues,
    handleInputChange,
    validateWorkspace,
    getCurrentWorkspace,
    getWorkspaceMembers,
    toggleWorkspaceModal,
    toggleOpenInviteModal,
    getWorkspaceToBeEdited,
    getWorkspaceInvitations,
    validateWorkspaceInvitation,
  };
}
